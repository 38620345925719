.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media ( prefers-reduced-motion : no-preference ) {

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
